import React from 'react';
import cx from 'classnames';
import logo from "../assets/images/logo.png";
import smh from "../assets/images/footer/smh.png";
import financialReview from "../assets/images/footer/financial-review.png";
import news from "../assets/images/footer/news.png";
import theadviser from "../assets/images/footer/theadviser.png";
import theAustralian from "../assets/images/footer/the-australian.png";
import courierLogo from "../assets/images/footer/courier-logo.svg";
import googlePlay from "../assets/images/footer/google-play-icon.png";
import appStore from "../assets/images/footer/app-store-icon.png";
import footerStyles from '../assets/styles/newFooter.module.css';
import { Link } from 'gatsby';
import Cookies from 'universal-cookie';

const LoginButton = (props) => {
    return (
        <a key={new Date} href={'/login'}>{'Broker Profile'}</a>
    )
}

const DashboardButton = (props) => {
    return (
        <a href={props.href} key={new Date} className={"dashboard-link"}>{'My Dashboard'}</a>
    )
}


const FooterLogoSection = props => {

    let isLogin = false;
    let isLoaded = '';
    let role = ''
    let loginClass = 'headrLogin', anchorLabel = '', decodedData = {}, loginLinkk = '/login', hasDashboardLink = false;
    if (typeof props.menu == 'object' && props.menu != null) {
        role = props.menu.type;
        isLoaded = true;
        console.log("Footer menu .... ==>");
        console.log(typeof props.menu.links)
    }

    if (props.menu != null
        && typeof props.menu.links != 'undefined'
        && props.menu.links.length > 0) {
        props.menu.links.map(link => {
            isLogin = true;
            let key = Object.keys(link)[0];
            console.log(key, "key found");
            if (key == 'My Dashboard') {
                hasDashboardLink = true
                loginClass = 'profileLogin';
                anchorLabel = key
                loginLinkk = link[key];
            }
        })
    }

    let hasWindow = false;

    if (typeof window != 'undefined') {
        hasWindow = true;
    }

    return (
        <div className={footerStyles.footerLogoSection}>
            <div className={'container'}>
                <div className={cx('row', footerStyles.footerTop)}>
                    <div className={cx('col-md-6', footerStyles.footerLogo)}>
                        <span className={cx(footerStyles.footerLogo)}>
                            <a href="/">
                                <img src={logo} alt="HFG Marketplace home loans" />
                            </a>
                        </span>
                        {/*<span className={cx(footerStyles.feturedInText)}>
                            is featured in
                        </span> */}
                        {/* <div className={cx(footerStyles.feturedImages)}>
                            <img src={smh} alt="smh" />
                            <img src={financialReview} alt="The Australian - Financial Review" />
                            <img src={news} alt="News.co.au" />
                            <img src={theadviser} alt="The Adviser" />
                            <img src={theAustralian} alt="The Australian" />
                            <img src={courierLogo} alt="courier-logo" />
                        </div> */}
                        {/* <div className={cx(footerStyles.footerSocial)}>
                            <span className={cx(footerStyles.socialIcon)}>
                                <a href="https://twitter.com/hashching" target="_blank">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34">
                                        <g id="Group_634" data-name="Group 634" transform="translate(-1756 -3503)">
                                            <path id="Path_586" data-name="Path 586" d="M22.269,11.216a.11.11,0,0,0-.13-.03,8.918,8.918,0,0,1-2.09.634A4.635,4.635,0,0,0,21.68,9.529a.111.111,0,0,0-.162-.129,8.939,8.939,0,0,1-2.781,1.073,4.644,4.644,0,0,0-7.894,4.133,12.793,12.793,0,0,1-9.11-4.683.108.108,0,0,0-.095-.04.11.11,0,0,0-.087.055A4.645,4.645,0,0,0,2.67,15.9a4.435,4.435,0,0,1-1.619-.523.111.111,0,0,0-.165.094v.059a4.655,4.655,0,0,0,3.261,4.432,4.456,4.456,0,0,1-1.542-.022.111.111,0,0,0-.126.142,4.639,4.639,0,0,0,4.027,3.206,8.917,8.917,0,0,1-5.316,1.733,9.176,9.176,0,0,1-1.067-.063.112.112,0,0,0-.117.072.111.111,0,0,0,.045.131,12.946,12.946,0,0,0,7,2.052,12.87,12.87,0,0,0,13-13q0-.267-.011-.531a9.252,9.252,0,0,0,2.238-2.337A.111.111,0,0,0,22.269,11.216Z" transform="translate(1762 3501.947)" fill="#fff" />
                                            <rect id="Rectangle_429" data-name="Rectangle 429" width="34" height="34" transform="translate(1756 3503)" fill="none" />
                                        </g>
                                    </svg>
                                </a>
                            </span>
                            <span className={cx(footerStyles.socialIcon)}>
                                <a href="https://www.facebook.com/hashching" target="_blank">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34">
                                        <g id="Group_635" data-name="Group 635" transform="translate(-1792 -3503)">
                                            <path id="Path_587" data-name="Path 587" d="M34.538,3.647H32.47c-1.622,0-1.936.771-1.936,1.9V8.044H34.4V11.95H30.534V21.973H26.5V11.95H23.127V8.044H26.5V5.163C26.5,1.82,28.542,0,31.524,0l3.014,0V3.647Z" transform="translate(1779.873 3509)" fill="#fff" />
                                            <rect id="Rectangle_430" data-name="Rectangle 430" width="34" height="34" transform="translate(1792 3503)" fill="none" />
                                        </g>
                                    </svg>
                                </a>
                            </span>
                            <span className={cx(footerStyles.socialIcon)}>
                                <a href="https://www.youtube.com/channel/UC8t1BlyjiO57jjk8_5vtZPg" target="_blank">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34">
                                        <g id="Group_637" data-name="Group 637" transform="translate(-1864 -3503)">
                                            <path id="Path_588" data-name="Path 588" d="M21.739,17.156a2.8,2.8,0,0,0-2.391-2.174,74.329,74.329,0,0,0-8.283-.306,74.341,74.341,0,0,0-8.284.306A2.794,2.794,0,0,0,.39,17.156,23.69,23.69,0,0,0,0,22.386a23.683,23.683,0,0,0,.385,5.229,2.794,2.794,0,0,0,2.391,2.174,74.275,74.275,0,0,0,8.284.306,74.261,74.261,0,0,0,8.283-.306,2.8,2.8,0,0,0,2.391-2.174,23.672,23.672,0,0,0,.387-5.229A23.687,23.687,0,0,0,21.739,17.156ZM8.206,25.819V18.5l7.033,3.669Z" transform="translate(1870 3497.324)" fill="#fff" />
                                            <rect id="Rectangle_432" data-name="Rectangle 432" width="34" height="34" transform="translate(1864 3503)" fill="none" />
                                        </g>
                                    </svg>
                                </a>
                            </span>

                            <span className={cx(footerStyles.socialIcon)}>
                                <a href="https://www.instagram.com/hashching" target="_blank">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34">
                                        <g id="Group_638" data-name="Group 638" transform="translate(-1900 -3503)">
                                            <g id="Group_633" data-name="Group 633" transform="translate(-32.505 -8.585)">
                                                <path id="Path_591" data-name="Path 591" d="M721.976,308.521h-6.43a1.3,1.3,0,0,0-.183-.031,10.417,10.417,0,0,1-2.244-.241,4.981,4.981,0,0,1-3.557-2.966,7.459,7.459,0,0,1-.518-2.7c-.008-.2-.029-.406-.044-.608v-6.43a1.291,1.291,0,0,0,.031-.183,10.424,10.424,0,0,1,.241-2.244,4.982,4.982,0,0,1,2.966-3.557,7.46,7.46,0,0,1,2.7-.518c.2-.008.406-.029.608-.044h6.43a1.3,1.3,0,0,0,.183.031,10.424,10.424,0,0,1,2.244.241,4.982,4.982,0,0,1,3.557,2.966,7.465,7.465,0,0,1,.518,2.7c.008.2.029.406.044.608v6.43a1.282,1.282,0,0,0-.031.183,10.414,10.414,0,0,1-.241,2.244,4.981,4.981,0,0,1-2.966,3.557,7.465,7.465,0,0,1-2.7.518C722.381,308.486,722.178,308.506,721.976,308.521Zm-11.23-9.771h.03c0,.819-.016,1.639,0,2.458.019.76.048,1.522.125,2.278a3.477,3.477,0,0,0,.83,1.985,3.582,3.582,0,0,0,2.606,1.183c1.132.069,2.268.087,3.4.1q1.732.021,3.465-.014c.76-.015,1.522-.048,2.278-.124a3.477,3.477,0,0,0,1.985-.831,3.582,3.582,0,0,0,1.183-2.606c.069-1.132.087-2.268.1-3.4q.021-1.732-.014-3.465c-.015-.76-.048-1.522-.125-2.278a3.478,3.478,0,0,0-.83-1.985,3.579,3.579,0,0,0-2.606-1.183c-1.152-.069-2.307-.086-3.461-.1-1.129-.014-2.259-.014-3.388.015a19.919,19.919,0,0,0-2.525.167,3.179,3.179,0,0,0-2.768,2.445,6.4,6.4,0,0,0-.21,1.43C710.78,296.132,710.771,297.441,710.745,298.751Z" transform="translate(1230.505 3229.585)" fill="#fff" />
                                                <path id="Path_592" data-name="Path 592" d="M841.526,416.518a5.016,5.016,0,1,1-5-5.025A5.013,5.013,0,0,1,841.526,416.518Zm-5.022,3.239a3.247,3.247,0,1,0-3.242-3.257A3.254,3.254,0,0,0,836.5,419.757Z" transform="translate(1112.756 3111.836)" fill="#fff" />
                                                <path id="Path_593" data-name="Path 593" d="M1067.4,377.34a1.177,1.177,0,1,1-1.182-1.175A1.179,1.179,0,0,1,1067.4,377.34Z" transform="translate(888.251 3145.796)" fill="#fff" />
                                            </g>
                                            <rect id="Rectangle_433" data-name="Rectangle 433" width="34" height="34" transform="translate(1900 3503)" fill="none" />
                                        </g>
                                    </svg>
                                </a>
                            </span>
                        </div> */}
                        <div className={cx("",footerStyles.companyInfo)}>
                            <nobr>ABN: 37651957630</nobr> | <nobr>AFSL: 238128</nobr> | <nobr>ACL: 500313</nobr> | <nobr>A Sapien Group Company</nobr>
                        </div>
                        {/* <div className={footerStyles.tollFree}>
                            <h4><a href="tel:1800 444 744"><span>1800 444 744</span></a></h4>
                        </div> */}
                    </div>
                    <div className={cx('col-md-6 text-right', footerStyles.footerLogo)}>
                        <span className={cx(footerStyles.socialIcon)}>
                            <a href="https://www.linkedin.com/company/hashfinancial" target="_blank">
                                <svg width="25" height="25" class="e-font-icon-svg e-fab-linkedin" viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg">
                                    <path fill="#02A1C1" d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"></path>
                                </svg>
                            </a>
                        </span>
                    </div>
                </div>
                <div className={'row'}>
                    <div className={cx('col-lg-9', footerStyles.footerNavigation)}>
                        <div className={'row'}>
                            <ul className={cx('col-md-3 col-sm-6', footerStyles.navListing, footerStyles.customrList)}>
                                <li className={cx(footerStyles.navHead)}>Home</li>
                                <li>
                                    <a href="https://hashfinancialgroup.com/our-team/">Our Team</a>
                                </li>
                                <li>
                                    <a title="Ask an expert" href="https://hashfinancialgroup.com/?page_id=272">Our Products</a>
                                </li>

                                <li>
                                    <a href="https://hashfinancialgroup.com/our-platforms/">Our Platforms</a>
                                </li>
                                <li>
                                    <a href="https://hashfinancialgroup.com.au/deals">Our Deals</a>
                                </li>
                            </ul>
                            <ul className={cx('col-md-3 col-sm-6', footerStyles.navListing, footerStyles.compList)}>
                                <li className={cx(footerStyles.navHead)}>Our Team</li>
                                <li>
                                    <a href="https://hashfinancialgroup.com.au/our-team">About Us</a>
                                </li>
                                <li>
                                    <a href="https://www.linkedin.com/company/hashfinancial">Career</a>
                                </li>
                                <li>
                                    <a href="https://www.linkedin.com/company/hashfinancial">We Are Hiring</a>
                                </li>
                                <li>
                                    <a href="https://hashfinancialgroup.com/contact/">Contact Us</a>
                                </li>
                            </ul>
                            <ul className={cx('col-md-3 col-sm-6', footerStyles.navListing, footerStyles.compList)}>
                                <li className={cx(footerStyles.navHead)}>Our Products</li>
                                <li>
                                    <a href="https://hashfinancialgroup.com/our-products/commercial-loans/">Commercial Loans</a>
                                </li>
                                <li>
                                    <a href="https://hashfinancialgroup.com/our-products/commercial-loans-2/">Residential Mortgages</a>
                                </li>
                                <li>
                                    <a href="https://hashfinancialgroup.com/asset-backed-credit-fund/">Asset-backed Credit Fund</a>
                                </li>
                                <li>
                                    <a href="https://hashfinancialgroup.com/term-deposits/">Term Deposits</a>
                                </li>
                            </ul> 
                            <ul className={cx('col-md-3 col-sm-6', footerStyles.navListing, footerStyles.compList)}>
                                <li className={cx(footerStyles.navHead)}>Our Platforms</li>
                                <li>
                                    <a href="/">HFG Marketplace</a>
                                </li>
                                <li>
                                    <a href="https://hashfinancialgroup.com/mystro/">Mystro</a>
                                </li>
                                <li>
                                    <a href="https://hashfinancialgroup.com/hashprop/">HashProp</a>
                                </li>
                                <li>
                                    <a href="https://hashfinancialgroup.com/news/">News & Insights</a>
                                </li>
                            </ul> 
                        </div>
                    </div>
                    <div className={cx('col-lg-3', footerStyles.footerAppservice)}>
                        <h5 className={cx(footerStyles.navHead)}>Get In Touch</h5>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FooterLogoSection;