import React, { Component } from "react";
import {Link} from 'gatsby';
import Cookies from 'universal-cookie';

class Utmtags extends Component {

    constructor(props){
      super(props);     
    }
     
   componentDidMount() {
      let params = {}, queries, temp, i, l, queryString ;
      queryString=window.location.href;
      if(queryString.indexOf('?') != -1){
         queryString = decodeURIComponent(queryString.substring( queryString.indexOf('?') + 1 ));
          queries = queryString.split("&");
          for ( i = 0, l = queries.length; i < l; i++ ) {
              temp = queries[i].split('=');
              if(temp.length >2){
                params[temp[0]] = `${temp[1]}=`;   
              }else{
                params[temp[0]] = temp[1];     
              }  
          }
      }

    if(Object.keys(params).length != 0){
         this.cookieSet('referrerId', params.id);
      if(params.utm_campaign || params.utm_medium || params.utm_source || params.utm_term){
          this.cookieSet('utm_campaign', params.utm_campaign);
          this.cookieSet('utm_medium', params.utm_medium);
          this.cookieSet('utm_source', params.utm_source);
          this.cookieSet('utm_term', params.utm_term);
       
      }
    }
  }

  cookieSet(variable, value = '') {
        let cookies = new Cookies();
        cookies.set(variable, value, { path: '/' });
        return cookies.get(variable)
    }
    
  render() {
    return (
         <div> </div>
    )
  }
};
export default Utmtags;