import React from 'react';
import cx from 'classnames';
import footerStyles from '../assets/styles/newFooter.module.css';


const FooterCopyrightSection = props => {

    return( 
   
            <div className={footerStyles.footerCopyright}>
                <div className={'container'}>
                    <div className={'row'}>
                    <div className={cx('col-md-8 col-sm-12', footerStyles.footerBottomLeft)}>
                    <span className={cx(footerStyles.footerCopyrightText)}>
                        &copy; 2024 Hash Financial Group
                        {/* &copy; HFG Marketplace Limited   ABN 99 600 101 184  
						<span className={cx(footerStyles.breakLine)}>&nbsp; | &nbsp; </span> 
                        <span className={cx(footerStyles.allrightsTxt)}>
                            <nobr>All Rights Reserved. &nbsp;</nobr>
                        </span> */}
                    </span>
                    {/* <span className={cx(footerStyles.policyLinks)}>
                    <a title="Terms of Use" href="/terms-conditions">Terms of Use &nbsp; | &nbsp;</a>
                    <a title="Privacy Policy" href="/privacy">Privacy policy</a>
                    </span> */}
                </div>
                {/* <div className={cx('col-md-4 col-sm-12', footerStyles.footerBottomRight)}>
                    
                </div> */}
                    </div>
                </div>
            </div>






            );
};

export default FooterCopyrightSection;