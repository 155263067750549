import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import '../assets/styles/bootstrap.min.css';
import Header from "./header";
import Footer from "./footer";
import "./layout.css";
// import "../assets/styles/googleFontsLato.css";
import "../assets/styles/googleFontsMontserrat.css";
import CustomHelmet from './customHelmet';
// import ChatScript from "./chatio/chatScript"; 
import Utmtags from "./utmtags"; 
import { log, host } from '../helpers/commons'
import Axios from "axios";
import allKenticoCloudItemSectionHeader from "../../data/kentico/allKenticoCloudItemSectionHeader";
import allKenticoCloudItemSectionFooter from "../../data/kentico/allKenticoCloudItemSectionFooter";

class LayoutNew extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      menu: null,
      isLoaded: false
    }
    this.onUpdateMenuData = this.onUpdateMenuData.bind(this);
  }

  onUpdateMenuData(menu, isLoaded) {
    log(menu.constructor, 'constructor');
     
    this.setState({
      menu: menu,
      isLoaded: isLoaded
    })
    
    log(arguments, "onupdateMenuData called");
  }

  componentDidMount () {
      // const script = document.createElement("script");
      // script.src = "/assets/js/frontend/zohotrack.js";
      // script.async = true;
      // document.body.appendChild(script);
  }

  // componentDidMount() {
  //   const url = host + `/gatsby-api/get-login-status`

  //   Axios.get(url, {

  //   }).catch(function (error) {
  //     console.error(error);
  //   }).then((response) => {
  //     this.setState({
  //       isLoaded: true
  //     })
  //     if (typeof response.data.type != 'undefined') {
  //       console.log(response.data)
  //       let data = response.data;
  //       let links = []
  //       Object.getOwnPropertyNames(data.links).forEach(key => {
  //         let obj = {};
  //         obj[key] = data.links[key];
  //         links.push(obj);
  //       })
  //       data.links = links;
  //       this.setState({
  //         menu: response.data,
  //         isLoaded: true
  //       })
  //     }
  //   })
  // }

  render() {
    let children = this.props.children;
    let menu = this.state.menu;
    let isLoaded = this.state.isLoaded;
    log(this.state, "layout state");
    return (
      <StaticQuery
        query={graphql`
      {
        site {
          siteMetadata {
            title
            description
            keywords
          }
        }      
      }
    `}
        render={(data) => (
          <div>
           <CustomHelmet>
       {/* <script id="ze-snippet" src="https://static.zdassets.com/ekr/snippet.js?key=17ac20c9-4f93-4b02-a610-5b7f897979d5"></script> */}
       {/* <script></script> */}
      </CustomHelmet>
            <Utmtags />
            <Header content={allKenticoCloudItemSectionHeader.edges[0].node} menu={this.state.menu} isLoaded={this.state.isLoaded} onUpdateMenuData={this.onUpdateMenuData} />
            {children}
            <Footer content={allKenticoCloudItemSectionFooter.edges[0].node} menu={this.state.menu} isLoaded={this.state.isLoaded} />
            {/* <FooterBottomContact/> */}
            {/* <ChatScript /> */}
          </div>
        )}>
      </StaticQuery>
    )
  }
}

// const Layout = ({children}) => (
//   <StaticQuery
//     query={graphql`
//       {
//   site {
//           siteMetadata {
//             title
//             description
//             keywords
//           }
//         }      
//   allKenticoCloudItemSectionHeader{
//     edges{
//       node{
//         elements{
//           header_logo {
//             value {
//               url
//             }
//           }
//           header_menu1{
//             elements{
//               label{
//                 value
//               }
//               href{
//                 value
//               }
//             }
//           }
//           header_menu2{
//              elements{
//               label{
//                 value
//               }
//               href{
//                 value
//               }
//             }
//           }
//           header_menu3{
//              elements{
//               label{
//                 value
//               }
//               href{
//                 value
//               }
//             }
            
//           }
//           label_login{
//             elements{
//               label{
//                 value
//               }
//               href{
//                 value
//               }
//             }
//           }
//           label_button{
//             elements{
//               label{
//                 value
//               }
//               href{
//                 value
//               }
//             }
//           }
//         }
//       }
//     }
//   }
//   allKenticoCloudItemSectionFooter
//   {
//     edges{
//         node {
//           elements{
//             footer_top_headingprefix{
//               value
//             }
//             footer_top_headingpostfix{
//               value
//             }
//             footer_top{
//               elements{
//                 image{
//                   value{
//                     url
//                   }
//                 }
//                 href{
//                   value
//                 }
//                 label_text
//                 {
//                   value
//                 }
//                 input_placeholder{
//                   value
//                 }
//                 button_text{
//                   value
//                 }
//               }
//             }
//             footer_middle{
//               elements{
//               label_heading{
//                 value
//               }
//                 menu1{
//                   elements{
//                     label{
//                       value
//                     }
//                     href{
//                       value
//                     }
//                   }
//                 }
//                 menu2{
//                   elements{
//                     label{
//                       value
//                     }
//                     href{
//                       value
//                     }
//                   }
//                 }
//                 menu3{
//                   elements{
//                     label{
//                       value
//                     }
//                     href{
//                       value
//                     }
//                   }
//                 }
//                 menu4{
//                   elements{
//                     label{
//                       value
//                     }
//                     href{
//                       value
//                     }
//                   }
//                 }
                
//               }
              
//             }
//             footer_middle_social_link{
//               elements{
//                 linkedin_url{
//                   value
//                 }
//                 twitter_url{
//                   value
//                 }
//                 facebook_url{
//                   value
//                 }
//                 instagram_url{
//                   value
//                 }
//                 youtube_url{
//                   value
//                 }
//               }
//             }
//             footer_bottom{
//               elements{
//                 label_heading{
//                   value
//                 }
//                 svg_file{
//                   value {
//                     url
//                   } 
//                 }
//                 label_text{
//                   elements{
//                     label{
//                       value
//                     }
//                     href{
//                       value
//                     }
//                   }
//                 }
//                 menu1{
//                   elements{
//                     label{
//                       value
//                     }
//                     href{
//                       value
//                     }
//                   }
//                 }
//                 menu2{
//                   elements{
//                     label{
//                       value
//                     }
//                     href{
//                       value
//                     }
//                   }
//                 }
//               }
//             }
//             footer_bottom_copyrights_prefix{
//               value
//             }
//             footer_bottom_copyrights_postfix_304bb0a{
//               value
//             }
//             footer_bottom_contact{
//               elements{
//                 label{
//                   value
//                 }
//                 href{
//                   value
//                 }
//                 svg_file{
//                   value{
//                     url
//                   }
//                 }
//                 label_text{
//                   elements{
//                     label{
//                       value
//                     }
//                     href{
//                       value
//                     }
//                   }
//                 }
//               }
//             }
//           }
//         }    
//     }  
//   }
// }
//     `}
//     render={(data) => (
//       <div>
//       <Utmtags />
//         <Header content={data.allKenticoCloudItemSectionHeader.edges[0].node}/>

//         {children}
//         <Footer content={data.allKenticoCloudItemSectionFooter.edges[0].node}/>
//         {/* <FooterBottomContact/> */}
//       <ChatScript />
//       </div>
//     )}>
//   </StaticQuery>
// );

export default LayoutNew;
