import React from 'react';
import cx from 'classnames';
import footerStyles from '../assets/styles/footerStyles.module.css';
// import FooterTop from "./footerTop";
// import FooterMiddle from "./footerMiddle";
// import FooterBottom from "./footerBottom";
// import FooterBottomContact from "./footerBottomContact";
import FooterLenders from "./footerLenders";
import FooterLogoSection from "./footerLogoSection";
import FooterCopyrightSection from "./footerCopyrightSection";


const Footer = props => {
  const content = props.content;
  if (!content) {
    return null;
  }
  const footerTop = content.elements,
    footerMiddle = content.elements.footer_middle,
    footerMiddleSocialLinks = content.elements.footer_middle_social_link,
    footerBottom = content.elements.footer_bottom,
    footerBottomHeadingPrefix = content.elements.footer_bottom_copyrights_prefix,
    footerBottomHeadingPostfix = content.elements.footer_bottom_copyrights_postfix_304bb0a,
    footerBottomContact = content.elements.footer_bottom_contact;
  return (
    <footer className={cx('footerSection', footerStyles.footerSection)}>

      {/* <FooterTop content={footerTop}/>
            <FooterMiddle content={footerMiddle} socialLinks={footerMiddleSocialLinks} menu={props.menu} />
            <FooterBottom content={footerBottom} headingPrefix={footerBottomHeadingPrefix} headingPostfix={footerBottomHeadingPostfix}/>
          */}

      {/* <FooterLenders/> */}
      <FooterLogoSection content={footerMiddle} socialLinks={footerMiddleSocialLinks} menu={props.menu} />
      <FooterCopyrightSection />

    </footer>

  );
};

export default Footer;